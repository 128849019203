import type { NormalizedRequestModel } from '@/services/models/Http';
import type { Nullable } from '@/type-utils';
import { InvalidStateError, NotImplementedError } from '@/utils/errors';
import { cache } from 'react';
import { rsc as isReactServerComponentContext } from 'rsc-env';

/** This interface represents the context of the running code. */
export interface IRunContext {
  /** The request that is being processed. */
  request: NormalizedRequestModel;
}

/**
 * The `RunContextService` fills a hole in our `CurrentRequestService`. It is used to store
 * and retrieve relevant information about the state of the current context. Currently
 * this is only useful for ServerComponents, if used in a client component it will throw
 * a `NotImplementedError`. Right now this error is expected in the `I18nService` and ignored.
 *
 * TODO: In the long run, after we have all routes on the App Router, we should reconsider
 * both this service and the `CurrentRequestService` and see what kind of refactor makes sense.
 */
export class RunContextService {
  private request: Nullable<NormalizedRequestModel> = null;

  /** @returns Whether there is a run context stored. */
  public get isContextSupported(): boolean {
    return isReactServerComponentContext;
  }

  private getContext = this.isContextSupported
    ? cache(() => this.request)
    : undefined;

  /**
   * @returns The cached run context.
   * @throws A {@link NotImplementedError} if the context is not supported. Use
   * {@link isContextSupported} to check for support.
   * @throws An {@link InvalidStateError} if the context has not been stored.
   */
  public get context(): IRunContext {
    if (!this.getContext) {
      throw new NotImplementedError(
        'The RunContextService cannot be used outside of a Server Component.'
      );
    }

    const context = this.getContext();

    if (!context) {
      throw new InvalidStateError(
        'Run context has not been stored. Do not retrieve it without storing it first.'
      );
    }

    return { request: context };
  }

  /**
   * Stores the context in the service.
   * @param req - The request to store.
   * @throws {NotImplementedError} If the context is not supported.
   */
  public storeContext(req: NormalizedRequestModel): void {
    if (!this.getContext) {
      throw new NotImplementedError(
        'The RunContextService cannot be used outside of a Server Component.'
      );
    }

    this.request = req;
    this.getContext(); // load the context into the cache
  }
}

export default new RunContextService();
