/* eslint-disable @typescript-eslint/strict-boolean-expressions -- Nearly every
nullable string value in this file intends to treat the empty string as a falsy
value. As a result, the rule is disabled to prevent unnecessary explicit checks. */

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing --
 * We disable this warning because `||` is actually preferred to `??` in this context
 * because the environment variable could possible come through as empty strings
 * rather than `undefined` or `null`, where empty strings are equally meaningless
 * here. */

/* eslint-disable prefer-destructuring --
 * Trying to destructure process.env variables won't work due to the nature of webpack DefinePlugin.
 * See https://nextjs.org/docs/api-reference/next.config.js/environment-variables */

// This env variable is set by our `DefinePlugin` in the webpack config.
export const BUILD_ID = process.env.BUILD_ID as string;

// This env variable is set by our `DefinePlugin` in the webpack config.
export const IS_DEVELOPMENT_MODE = process.env
  .IS_DEVELOPMENT_MODE as unknown as boolean;

export const DEPLOYMENT_ENV = (process.env.VERCEL_ENV ??
  process.env.NEXT_PUBLIC_VERCEL_ENV ??
  process.env.NODE_ENV) as 'production' | 'preview' | 'development';

export const APP_ENV: 'dev' | 'qa' | 'uat' | 'prod' =
  process.env.NEXT_PUBLIC_APP_ENV === 'dev' ||
  process.env.NEXT_PUBLIC_APP_ENV === 'qa' ||
  process.env.NEXT_PUBLIC_APP_ENV === 'uat' ||
  process.env.NEXT_PUBLIC_APP_ENV === 'prod'
    ? process.env.NEXT_PUBLIC_APP_ENV
    : 'dev';
export const IS_NODE_ENV_PRODUCTION = process.env.NODE_ENV === 'production';
export const IS_NODE_ENV_DEVELOPMENT = process.env.NODE_ENV === 'development';

export const SITE_BRAND =
  process.env.NEXT_PUBLIC_SITE_BRAND?.toUpperCase() || 'AHNU';

export const BASE_URL =
  // Ideally, this environment variable should _always_ be set.
  (
    process.env.NEXT_PUBLIC_BASE_URL ||
    // Some environment, such as preview environment, use a dynamically generated URL.
    // In those environments, Vercel should know the current base URL.
    process.env.VERCEL_URL ||
    // If neither of those are set, it's more than likely that we're running the code
    // locally. We'll assume we're on locale host.
    'http://localhost:3000'
  )
    // Regex matches any string not starting with `https?://`, gets the hostname
    // (anything up until the first slash), and  prepends `https://`. This is done
    // in case the `BASE_URL` comes through with either no protocol or a
    // trailing slash.
    .replace(/^(?!https?:\/\/)(^[^/]+)((\/+)[\w]*)*$/g, 'https://$1')
    // This regex additionally removes and trailing slash or trailing path, in case
    // the previous regex did not match, but there was still a trailing slash or path.
    .replace(/((?:^https?:\/\/)(?:[^/]+?))\/+.*?$/, '$1');

export const VERCEL_BRANCH_URL =
  process.env.VERCEL_BRANCH_URL ??
  process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL ??
  'http://localhost:3000';

/**
 * Whether or not ecommerce functionality is enabled for the current environment. Controls
 * whether pages and features such as cart, account, checkout, and returns are able to be
 * accessed.
 */
export const ECOMM_ENABLED = process.env.NEXT_PUBLIC_ECOMM_ENABLED
  ? // If the environment variable is set, then it must be set to the string "true" in order
    // to enable ecommerce functionality.
    process.env.NEXT_PUBLIC_ECOMM_ENABLED.trim().toLowerCase() === 'true'
  : // If the environment variable is not set at all, default to being enabled.
    true;

/**
 * The name of the cookie that can be used to override the value of {@link ECOMM_ENABLED}
 * and force-enable ecommerce functionality.
 *
 * For the server-side, the value of this cookie must be set to a known secret string in
 * order to force-enable ecommerce functionality. For the client-side, this cookie must
 * merely be present to show UI elements related to ecommerce functionality. These UI
 * elements have no practical effect if the server-side does not also force-enable
 * ecommerce functionality.
 */
export const ECOMM_OVERRIDE_COOKIE_NAME =
  process.env.NEXT_PUBLIC_ECOMM_OVERRIDE_COOKIE_NAME ||
  'force-enable-ecommerce';

export const ELASTIC_PATH_URL = process.env.NEXT_PUBLIC_ELASTIC_PATH_URL;
export const DEFAULT_REGION = process.env.NEXT_PUBLIC_DEFAULT_REGION || 'US';
export const REGION = process.env.NEXT_PUBLIC_REGION || 'NA';

export const GTM_CONTAINER_ID = process.env.NEXT_PUBLIC_GTM_CONTAINER_ID;
export const GTM_AUTH = process.env.NEXT_PUBLIC_GTM_AUTH;
export const GTM_PREVIEW = process.env.NEXT_PUBLIC_GTM_PREVIEW;

export const COVEO_ORG_ID = process.env.NEXT_PUBLIC_COVEO_ORG_ID;
export const COVEO_API_KEY = process.env.NEXT_PUBLIC_COVEO_ACCESS_TOKEN;
export const COVEO_SOURCE = process.env.NEXT_PUBLIC_COVEO_SOURCE;

export const ELASTIC_PATH_CLIENT_ID =
  process.env.NEXT_PUBLIC_ELASTIC_PATH_CLIENT_ID;
export const ELASTIC_PATH_CURRENCY =
  process.env.NEXT_PUBLIC_ELASTIC_PATH_CURRENCY;
export const ELASTIC_PATH_LANGUAGE =
  process.env.NEXT_PUBLIC_ELASTIC_PATH_LANGUAGE;

export const COREMEDIA_HOMEPAGE_SEGMENT =
  process.env.COREMEDIA_HOMEPAGE_SEGMENT;

export const COREMEDIA_ENABLE_PREVIEW =
  process.env.NEXT_PUBLIC_COREMEDIA_ENABLE_PREVIEW === 'true';

export const COREMEDIA_BASE_URL = process.env.NEXT_PUBLIC_COREMEDIA_BASE_URL;
export const COREMEDIA_PREVIEW_BASE_URL =
  process.env.NEXT_PUBLIC_COREMEDIA_PREVIEW_BASE_URL;
export const COREMEDIA_ACCESS_TOKEN = process.env.COREMEDIA_ACCESS_TOKEN;
export const COREMEDIA_PREVIEW_ACCESS_TOKEN =
  process.env.COREMEDIA_PREVIEW_ACCESS_TOKEN;

export const COREMEDIA_REVALIDATE_THRESHOLD =
  process.env.NEXT_PUBLIC_COREMEDIA_REVALIDATE_THRESHOLD;

export const ELASTIC_PATH_HOST_NAME =
  process.env.NEXT_PUBLIC_ELASTIC_PATH_HOST_NAME;

export const LOGGER_DATADOG_HOST = process.env.NEXT_PUBLIC_DATADOG_LOGGER_HOST;
export const LOGGER_DATADOG_PATH = process.env.NEXT_PUBLIC_DATADOG_LOGGER_PATH;

export const SESSION_ID_KEY =
  process.env.NEXT_PUBLIC_SESSION_ID_KEY ?? 'deckersSID';
export const CSRF_TOKEN_COOKIE =
  process.env.NEXT_PUBLIC_CSRF_TOKEN_COOKIE ?? 'csrfToken';
export const CSRF_TOKEN_HEADER =
  process.env.NEXT_PUBLIC_CSRF_TOKEN_HEADER ?? 'x-csrf-token';
export const USER_ID_KEY =
  process.env.NEXT_PUBLIC_USER_ID_KEY ?? 'deckersUserID';

export const NORMALIZED_REQUEST_URL_HEADER_KEY =
  process.env.NEXT_PUBLIC_NORMALIZED_REQUEST_URL_HEADER_KEY ?? 'x-deca-nr-url';
export const NORMALIZED_REQUEST_IP_HEADER_KEY =
  process.env.NEXT_PUBLIC_NORMALIZED_REQUEST_IP_HEADER_KEY ?? 'x-deca-nr-ip';

export const APPLE_PAY_MERCHANT_IDENTIFIER =
  process.env.APPLE_PAY_MERCHANT_IDENTIFIER;

/**
 * This cookie is used to determine when to trigger the Magtek/Endless Aisle experience. The
 * value of this cookie corresponds to a type of user that possesses special privileges and is used
 * on the website by associates in-store.
 *
 * If the value of this cookie is set to "Contact Center" or a number that corresponds to
 * a valid store id, it will trigger the Magtek checkout flow and the Magtek “infinite pos” banner experiences.
 */
export const ENDLESS_AISLE_COOKIE_NAME =
  process.env.ENDLESS_AISLE_COOKIE_NAME ?? 'storeid';

export const OTEL_SERVICENAME =
  process.env.NEXT_PUBLIC_OTEL_SERVICENAME ?? 'deca-frontend';

export const DATADOG_SERVICE = process.env.NEXT_PUBLIC_DATADOG_SERVICE;
export const DATADOG_SESSION_SAMPLE_RATE =
  process.env.NEXT_PUBLIC_DATADOG_SESSION_SAMPLE_RATE;
export const DATADOG_SESSION_REPLAY_SAMPLE_RATE =
  process.env.NEXT_PUBLIC_DATADOG_SESSION_REPLAY_SAMPLE_RATE;
export const DATADOG_ENV = process.env.NEXT_PUBLIC_DATADOG_ENV;
export const DATADOG_VERSION = process.env.NEXT_PUBLIC_DATADOG_VERSION;

export const PAYPAL_CLIENT_ID = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID;

export const CONDUCTOR_SSO_HOSTED_UI_URL =
  process.env.NEXT_PUBLIC_CONDUCTOR_SSO_HOSTED_UI_URL;
export const CONDUCTOR_SSO_CLIENT_ID =
  process.env.NEXT_PUBLIC_CONDUCTOR_SSO_CLIENT_ID;
export const CONDUCTOR_SSO_USER_POOL_ID =
  process.env.NEXT_PUBLIC_CONDUCTOR_SSO_USER_POOL_ID;
export const CONDUCTOR_SSO_REQUIRED_GROUP =
  process.env.NEXT_PUBLIC_CONDUCTOR_SSO_REQUIRED_GROUP;
export const CONDUCTOR_SSO_LOGIN_PAGE =
  process.env.NEXT_PUBLIC_CONDUCTOR_SSO_LOGIN_PAGE;
export const CONDUCTOR_SSO_TOKEN_KEY =
  process.env.NEXT_PUBLIC_CONDUCTOR_SSO_TOKEN_KEY || 'idToken';
export const CONDUCTOR_SSO_REFRESH_TOKEN_KEY =
  process.env.NEXT_PUBLIC_CONDUCTOR_REFRESH_TOKEN_KEY || 'refreshToken';
